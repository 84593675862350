import styled from "styled-components"

export const LoadMoreButton = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  padding: 8px 18px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  border: none;
  box-shadow: 0px 3px 4px rgba(73, 96, 234, 0.15);
  transition: 0.2s;

  :hover {
    background: #3750e7;
  }
`

export const LoadMoreContainer = styled.div`
  text-align: center;
  margin-top: 24px;
`
