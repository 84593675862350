import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

export const GridContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-auto-rows: 1fr;
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`
export const Card = styled.div`
  color: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 4px rgba(35, 34, 43, 0.25);
`

export const CardTags = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: ${props => (props.center ? "center" : "")};
`

export const CardTag = styled.div`
  background-color: ${props => (props.red ? props.theme.colors.primary : "")};
  padding: 4px 8px;
  margin-top: 8px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: ${props => (props.right ? "8px" : 0)};
`

export const Tag = styled.small`
  font-size: 14px;
  font-weight: 700;
  color: ${props => (props.white ? props.theme.colors.white : "")};
`

export const CardLink = styled(GatsbyLink)`
  text-decoration: none;
  margin: 0;
  background-color: ${props => props.theme.colors.gameCardBg};
`
