import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import moment from "moment"
import Layout from "../components/layout"
import SEO from "../components/seo"
import removeDuplicateTags from "../utils/removeDuplicateTags"
import TagSearch from "../components/tagSearch"
import {
  LoadMoreButton,
  LoadMoreContainer,
} from "../components/styled/loadMore"
import { NoData } from "../components/styled/noData"
import { SearchInput, SearchWrapper } from "../components/styled/search"
import {
  GridContainer,
  Card,
  CardTags,
  CardTag,
  Tag,
  CardLink,
} from "../components/styled/grid"
import { Container } from "../components/styled/container"
import { AdBanner, AdLayout } from "../components/adBanner/index"

const ArticlesPage = ({ data }) => {
  const [query, searchQuery] = useState("")
  const [articles, setArticles] = useState(data.allStrapiArticle.edges)
  const [tags, filterTags] = useState([]) // Renders them inside select component
  const [selectedTags, selectTag] = useState([])
  const [filteredArticles, setFilteredArticles] = useState([]) // Filtered Games
  const [limit, setLimit] = useState(10)

  useEffect(() => {
    // Probably fails if there are no articles at the start
    articles.map(({ node }) => {
      return node.tags.forEach(tag => {
        tags.push({
          value: tag,
          label: tag,
        })
      })
    })

    filterTags(removeDuplicateTags(tags))
  }, [])

  useEffect(() => {
    if (selectedTags !== null && selectedTags.length > 0) {
      let checker = (arr, target) => target.every(v => arr.includes(v))
      let currentTags = selectedTags.map(({ value }) => value)
      const matchOnTag = articles.filter(({ node }) => {
        return checker(node.tags, currentTags)
      })

      setFilteredArticles(
        matchOnTag.filter(({ node }) => {
          return node.title.toLowerCase().includes(query.toLowerCase())
        })
      )
    } else {
      setFilteredArticles(
        articles.filter(({ node }) => {
          return node.title.toLowerCase().includes(query.toLowerCase())
        })
      )
    }
  }, [query, articles])

  useEffect(() => {
    if (selectedTags !== null && selectedTags.length > 0) {
      searchQuery("")
      let checker = (arr, target) => target.every(v => arr.includes(v))
      let currentTags = selectedTags.map(({ value }) => value)
      setFilteredArticles(
        articles.filter(({ node }) => {
          return checker(node.tags, currentTags)
        })
      )
    } else {
      searchQuery("")
      setFilteredArticles(articles)
    }
  }, [selectedTags])

  const loadMoreArticles = () => {
    setLimit(limit + 10)
  }

  return (
    <Layout>
      <AdLayout>
        <AdBanner slot="6517054732" />
        <Container main style={{ flex: "10 0 auto" }}>
          <SEO
            title="Articles - FGCharts"
            description={data.strapiSeo.articlespage}
          />
          <SearchWrapper>
            <TagSearch selectTag={selectTag} tags={tags} />
            <SearchInput
              placeholder="Search Articles..."
              onChange={e => searchQuery(e.target.value)}
              value={query}
            />
          </SearchWrapper>
          <GridContainer>
            {filteredArticles.length > 0 ? (
              filteredArticles.slice(0, limit).map((node, index) => (
                <CardLink to={`/articles/${node.node.slug}`} key={index}>
                  <Card>
                    <div>
                      <Img
                        fluid={node.node.featuredImage.childImageSharp.fluid}
                        style={{ height: 224 }}
                      />
                    </div>
                    <div style={{ padding: "7px 16px 16px 16px" }}>
                      <h3
                        style={{
                          marginBottom: 8,
                        }}
                      >
                        {node.node.title}
                      </h3>
                      <p style={{ marginBottom: 8, fontSize: 14 }}>
                        {node.node.description}
                      </p>
                      <div>
                        <CardTags>
                          {node.node.tags.slice(0, 3).map((tag, index) => {
                            return (
                              <CardTag red key={index} right>
                                <Tag>{tag}</Tag>
                              </CardTag>
                            )
                          })}
                          <CardTag>
                            {node.node.tags.length - 3 > 0 ? (
                              <Tag>{node.node.tags.length - 3}+</Tag>
                            ) : undefined}
                          </CardTag>
                        </CardTags>
                        <div
                          style={{
                            marginTop: 16,
                          }}
                        >
                          <small>{`${node.node.author} - ${moment(
                            node.node.createdAt
                          ).format("LL")}`}</small>
                        </div>
                      </div>
                    </div>
                  </Card>
                </CardLink>
              ))
            ) : (
              <NoData style={{ gridColumn: "span 3" }}>
                There are currently no articles that fit the criteria.
              </NoData>
            )}
          </GridContainer>
          {filteredArticles.length > 10 && limit < filteredArticles.length ? (
            <LoadMoreContainer>
              <LoadMoreButton onClick={loadMoreArticles}>
                Show More
              </LoadMoreButton>
            </LoadMoreContainer>
          ) : undefined}
        </Container>
        <AdBanner slot="6517054732" />
      </AdLayout>
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiArticle(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          title
          slug
          description
          content
          author
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 360) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          id
          strapiId
          tags
          createdAt
        }
      }
    }
    strapiSeo {
      articlespage
    }
  }
`

export default ArticlesPage
